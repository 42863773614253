'use strict';

var BASE_URL = 'https://gbipapiuat.sandbox.dbt.svs.nike.com/api',
  REST_URL = {
    base: BASE_URL,
    account: BASE_URL + '/account/info',
    gtin: BASE_URL + '/search/caps',
    jobs: BASE_URL + '/jobs',
    search: BASE_URL + '/search',
    queueSearch: BASE_URL + '/search/jobs',
    facets: BASE_URL + '/search/facets/',
    requestCode: BASE_URL + '/account/generate',
    reports: BASE_URL + '/cpspub/sync/reports',
    resolve: {
      reactivate: BASE_URL + '/cpspub/sync/resolve/reactivate',
      duplicate: BASE_URL + '/cpspub/sync/resolve/duplicate'
    },
    merchSearch: BASE_URL + '/merch/search',
    merch: BASE_URL + '/merch',
    merchShipments: BASE_URL + '/merch/shipments',
    keyPrivate: BASE_URL + '/keys?for=printing&scope=private',
    keyPublic: BASE_URL + '/keys?for=printing&scope=public',
    assetRequest: {
      upload: [BASE_URL, '/assets/requests'].join(''),
      filtered: [BASE_URL, '/assets/requests/filters'].join(''),
      template: [BASE_URL, '/assets/requests/template'].join(''),
      request: [BASE_URL, '/assets/requests'].join('')
    },
    productLink: {
      upload: [BASE_URL, '/products/links'].join(''),
      template: [BASE_URL, '/products/links/template'].join(''),
      download: [BASE_URL, '/products/links'].join('')
    },
    deliveryJobs: BASE_URL + '/jobs/delivery',
    assetMetadata: BASE_URL + '/content/studio_assets/v1/',
    data: {
      merchShootTypes: BASE_URL + '/data/merch/shoot-types'
    }
  };

REST_URL.upload = {
  bulkJob: [REST_URL.jobs, '/bulkimport'].join(''),
  viewCode: [REST_URL.base, '/view/import/csv'].join('')
};

module.exports = REST_URL;
